import { mapHelper } from 'common/utils'

// 类目状态-检查类目
const categoryStatus = [
  {
    text: '开启',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: categoryStatusMap,
  setOps: categoryStatusOps
} = mapHelper.setMap(categoryStatus)

// 类目级别
const categoryLevel = [
  {
    text: '一级类目',
    value: 1
  },
  {
    text: '二级类目',
    value: 2
  },
  {
    text: '三级类目',
    value: 3
  },
  {
    text: '四级类目',
    value: 4
  },
  {
    text: '五级类目',
    value: 5
  }
]

const {
  map: categoryLevelMap,
  setOps: categoryLevelOps
} = mapHelper.setMap(categoryLevel)

// 资产状态-检查标准库/检查表模板
const status = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '停用',
    value: 1
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 显示模式-检查表模板
const displayModel = [
  {
    text: '按评分',
    value: 1
  },
  {
    text: '按是否合格',
    value: 2
  }
]

const {
  map: displayModelMap,
  setOps: displayModelOps
} = mapHelper.setMap(displayModel)

// 是否为关键项-检查表模板
const isKeyItem = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: isKeyItemMap,
  setOps: isKeyItemOps
} = mapHelper.setMap(isKeyItem)

// 按钮列表
const btnOps = [
  {
    text: '新增类目',
    title: '新增类目',
    value: 'addPzBtn',
  },
  
  {
    text: '编辑类目',
    title: '编辑类目',
    value: 'editPzBtn',
  },
  {
    text: '删除类目',
    title: '删除类目',
    value: 'deletePzBtn',
  },
]

export {
  categoryStatusMap,
  categoryStatusOps,
  categoryLevelMap,
  categoryLevelOps,
  statusMap,
  statusOps,
  isKeyItemOps,
  isKeyItemMap,
  displayModelMap,
  displayModelOps,
  btnOps,
}
