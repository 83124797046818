<!--
 * @Description: 品质管理检查标准库-表单页面
 * @Author: majun
 * @Date: 2021-04-27 09:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityStandardLibraryForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      :form="form"
      @update="update"
    >
      <col2-detail>
        <div style="display: flex;">
          <el-form-item
            label="类目"
            prop="categoryName"
          >
            <span>{{categoryName}}</span>
        </el-form-item>
        </div>

        <el-form-item
          label="检查项名称"
          prop="name"
          :rules="[ { required: true, message: '检查项名称不允许为空', trigger: 'blur' } ]"
        >
          <v-input
            v-model="form.name"
            placeholder="请填写检查项名称，限50个字符"
            :maxlength="50"
            :width="width"
          />
        </el-form-item>
        <el-form-item label="检查项标准" prop="criterion">
          <v-textarea v-model="form.criterion" :width="width" :maxlength="1000" />
        </el-form-item>
        <el-form-item label="检查方法及判定细则" prop="description">
          <v-textarea v-model="form.description" :width="width" :maxlength="1000" />
        </el-form-item>
        <el-form-item label="状态" prop="status"
          :rules="[ { required: true, message: '当前选项不允许为空', trigger: 'change' } ]"
        >
          <v-select v-model="form.status" :options="statusOps" :width="220"/>
        </el-form-item>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  getQualityCheckItemInfoByIdURL,
  // getItemNumberByCategoryIdURL,
  getCheckCategoryListSelectURL,
  addQualityCheckItemURL,
  updateQualityCheckItemURL
} from './api'
import {
  statusMap,
  statusOps
} from './map'
import {
  Col2Detail
  // Col2Block
  // Col2Item,
} from 'components/bussiness'

export default {
  name: 'qualityStandardLibraryForm',
  components: {
    Col2Detail
    // Col2Block
    // Col2Item,
  },

  data () {
    return {
      width: 580,
      form: {
        name: '',
        criterion: '',
        description: '',
        status: undefined,
        categoryId: ''
      },
      submitConfig: {
        queryUrl: getQualityCheckItemInfoByIdURL
      },
      statusOps: statusOps(2),
      statusMap,
      onOff: true,
      categoryName: ''
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id !== undefined
    },
    submitUrl () {
      return this.isEdit ? updateQualityCheckItemURL : addQualityCheckItemURL
    },
  },
  mounted () {
    const { id, categoryName, categoryId } = this.$route.query
    this.categoryName = categoryName
    this.form.categoryId = categoryId
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id })
    }
    this.onOff = !!id
    this.$setBreadcrumb(`${id ? '编辑' : '新增'}`)
  },
  methods: {
    update (data) { // 更新数据
      Object.keys({ ...this.form, id: '' }).forEach(key => {
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      this.$nextTick(() => {
        this.onOff = false
      })
    },
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    },
    levelCategoryToOps (arr) { // 一级、二级、三级类目数据格式转换
      let list = []
      arr.forEach(item => {
        list.push({
          text: item.categoryName,
          value: item.id
        })
      })
      // const { setOps } = mapHelper.setMap(list)
      // return all ? setOps(1) : list
      return list
    }
  }
}
</script>
<style lang="scss" scoped>
.qualityStandardLibraryForm-wrapper {
}
</style>
