var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualityStandardLibraryForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { submitUrl: _vm.submitUrl, form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类目", prop: "categoryName" } },
                    [_c("span", [_vm._v(_vm._s(_vm.categoryName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "检查项名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "检查项名称不允许为空",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请填写检查项名称，限50个字符",
                      maxlength: 50,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "检查项标准", prop: "criterion" } },
                [
                  _c("v-textarea", {
                    attrs: { width: _vm.width, maxlength: 1000 },
                    model: {
                      value: _vm.form.criterion,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "criterion", $$v)
                      },
                      expression: "form.criterion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "检查方法及判定细则", prop: "description" } },
                [
                  _c("v-textarea", {
                    attrs: { width: _vm.width, maxlength: 1000 },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    prop: "status",
                    rules: [
                      {
                        required: true,
                        message: "当前选项不允许为空",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.statusOps, width: 220 },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }