
/** 检查类目 */
const getQualityCheckCategoryListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckCategoryList`

/** 资产管理列表 */
// 列表查询
const getcategoryConfigurationListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/categoryConfigurationList`
// 配置类目下拉列表
const getCheckCategoryListSelectURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getCheckCategoryListSelect`
// 关闭类目前查询类目关联的检查标准数量
const getItemNumberByCategoryIdURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getItemNumberByCategoryId`
// 类目管理关闭-开启类目
const closeOrOpenQualityCheckCategoryByIdURL = `${API_CONFIG.butlerBaseURL}qualityManagement/closeOrOpenQualityCheckCategoryById`

/** 配置类目 */
// 列表
const categoryConfigurationListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/categoryConfigurationList`
// 详情
const getQualityCheckCategoryInfoByIdURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckCategoryInfoById`
// 新增类目
const addQualityCheckCategoryURL = `${API_CONFIG.butlerBaseURL}qualityManagement/addQualityCheckCategory`
// 编辑类目
const updateQualityCheckCategoryURL = `${API_CONFIG.butlerBaseURL}qualityManagement/updateQualityCheckCategory`

/* 检查标准库 */
// 列表
const getQualityCheckItemListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckItemList`
// 详情
const getQualityCheckItemInfoByIdURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckItemInfoById`
// 新增类目
const addQualityCheckItemURL = `${API_CONFIG.butlerBaseURL}qualityManagement/addQualityCheckItem`
// 编辑类目
const updateQualityCheckItemURL = `${API_CONFIG.butlerBaseURL}qualityManagement/updateQualityCheckItem`

/** 检查表模板 */
// 列表
const getQualityCheckTemplateListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTemplateList`
// 详情
const getQualityCheckTemplateInfoByIdURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTemplateInfoById`
// 新增类目
const addQualityCheckTemplateURL = `${API_CONFIG.butlerBaseURL}qualityManagement/addQualityCheckTemplate`
// 编辑类目
const updateQualityCheckTemplateURL = `${API_CONFIG.butlerBaseURL}qualityManagement/updateQualityCheckTemplate`
// 查询检查项-批量添加列表
const getQualityCheckItemList2URL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckItemList2`
// 开启关闭检查表模板
const closeOrOpenQualityCheckTemplateURL = `${API_CONFIG.butlerBaseURL}qualityManagement/closeOrOpenQualityCheckTemplate`

// 获取品质检查类目树
const getQualityCheckCategoryTreeURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckCategoryTree`

// 删除检查类目
const deleteQualityCheckCategoryByIdURL =  `${API_CONFIG.butlerBaseURL}qualityManagement/deleteQualityCheckCategoryById`


// 导入检查标准
const importQualityCheckItemURL = `${API_CONFIG.butlerBaseURL}qualityManagement/exportQualityCheckItem`


// 批量停用检查标准
const batchStopUsingQualityCheckItemURL = `${API_CONFIG.butlerBaseURL}qualityManagement/batchStopUsingQualityCheckItem`

export {
  getQualityCheckCategoryListURL,
  getcategoryConfigurationListURL,
  getCheckCategoryListSelectURL,
  getItemNumberByCategoryIdURL,
  closeOrOpenQualityCheckCategoryByIdURL,
  categoryConfigurationListURL,
  getQualityCheckCategoryInfoByIdURL,
  addQualityCheckCategoryURL,
  updateQualityCheckCategoryURL,
  getQualityCheckItemListURL,
  getQualityCheckItemInfoByIdURL,
  addQualityCheckItemURL,
  updateQualityCheckItemURL,
  getQualityCheckTemplateListURL,
  getQualityCheckTemplateInfoByIdURL,
  addQualityCheckTemplateURL,
  updateQualityCheckTemplateURL,
  getQualityCheckItemList2URL,
  closeOrOpenQualityCheckTemplateURL,
  getQualityCheckCategoryTreeURL,
  deleteQualityCheckCategoryByIdURL,
  importQualityCheckItemURL,
  batchStopUsingQualityCheckItemURL
}
